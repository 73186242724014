/** HELPERS */
import './Helpers/Polyfill';
import {MainApp} from './App';

/** STYLES */
import '../scss/Index.scss';

class IndexController {
    constructor (){
        // Theme scripts init here
        const app = new MainApp();
    }
}

// Loader babel polyfill only if needed
const BROWSER_UNSUPPORT_FEATURES = !window.Promise || !window.fetch || !window.Symbol || !Object.assign;

if (BROWSER_UNSUPPORT_FEATURES && typeof __jsVars !== 'undefined' && typeof __jsVars.dist !== 'undefined' && typeof __jsVars.dist.babelPolyfill !== 'undefined') {
    const js = document.createElement('script');
    js.src = __jsVars.dist.babelPolyfill;
    js.onload = function() {
        new IndexController();
    };
    js.onerror = function() {
        new IndexController();
        new Error('Failed to load script ' + __jsVars.dist.babelPolyfill);
    };
    document.head.appendChild(js);
} else {
    new IndexController();
}
