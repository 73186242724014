import { tns } from "tiny-slider/src/tiny-slider";
import  "tiny-slider/src/tiny-slider.scss";
import Macy from  "macy/dist/macy";
import lightGallery from  "fslightbox/index";
import animateScrollTo from 'animated-scroll-to';
import AOS from 'aos';
import 'aos/dist/aos.css';
  
export class MainApp {
    constructor() {
        this.setupMobileMenu();
        this.setupScrollLinks();
        this.createSlider();
        this.createMasonry();
        this.initialiseLightGallery(); 
        this.initialiseScrollAnimations();       
        this.watchActiveMenu();

        window.addEventListener('scroll',this.handleScroll);
        window.scrollTo(0,window.scrollY);
         
        const mobileButton = document.querySelector('.js--mobile-button');
        if(mobileButton) {
            mobileButton.addEventListener('click',this.handleMobileButton);
        }        
    }

    watchActiveMenu() {
        window.addEventListener('scroll', function() {
            let scrollDistance = this.scrollY;
            const sections = this.document.querySelectorAll('.scroll-section');
            
            if(this.document.querySelector(".section-slider")) {
                if(scrollDistance <= this.document.querySelector(".section-slider").offsetHeight - 300 ) {
                    const scrollLi = document.querySelectorAll('.js--primary-menu .menu-item');
                    for(let item of scrollLi) {
                        item.classList.remove('current-menu-item');
                        if(item.classList.contains('menu-item-home')) {                 
                            item.classList.add('current-menu-item');
                        }
                    }
                    return;
                }
            }

            for(let section of sections) {
                if(section.offsetTop - 150 <= scrollDistance) {                 
                    const scrollLi = document.querySelectorAll('.js--primary-menu .menu-item');
                    for(let item of scrollLi) {
                        item.classList.remove('current-menu-item');
                        if(section.id === item.querySelector('a').hash.substring(1)) {
                            item.classList.add('current-menu-item');
                        } else if(item.classList.contains(section.id)) {
                            item.classList.add('current-menu-item');
                        }
                    }
                }
            }
        });
    }

    updateActiveMenuItem() {

    }

    initialiseScrollAnimations() {
        AOS.init({
            delay: 100, 
            duration: 1000
        });
    }

    initialiseLightGallery(selector = '.js--gallery') {
        if(!this.elementExists(selector)) {
            return false;
        }
        this.gallery = new FsLightbox();
    }

    setupScrollLinks(speed = 700) {
        const scrollLinks = document.querySelectorAll('.scroll-to > a');
        scrollLinks.forEach(link => {
            link.addEventListener('click',function(e){               
                if(document.querySelector(this.hash)) {
                   e.preventDefault();                    
                   animateScrollTo(document.querySelector(this.hash).offsetTop - 30,{speed: speed}); 
                }                
            });
        });
    }

    setupMobileMenu() {
        const mobileMenuItems = document.querySelectorAll('.js--mobile-menu a');
        mobileMenuItems.forEach(element => {
            element.addEventListener('click',function(){
                document.querySelector('.js--mobile-menu').classList.remove('active');
                document.querySelector('.js--mobile-button').classList.remove('active');
            });
        });  
    }

    handleMobileButton() {
        const mobileMenu = document.querySelector('.js--mobile-menu');
        if(mobileMenu) {
            mobileMenu.classList.toggle('active');
            this.classList.toggle('active');
        }
    }

    handleScroll(e) {
        const header = document.querySelector('.js--header');
        if(header) {
            if(window.scrollY > 180) { //header height
             header.classList.add('fixed');
            } else if(window.scrollY < 100) {
                header.classList.remove('fixed');
            }
        }

    }

    createMasonry(selector = '.js--masonry') {
        if(!this.elementExists(selector)) {
            return false;
        }

        this.masonry = new Macy({
            container: selector,
            columns: 3,
            breakAt: {
                576: {
                    columns: 1,
                },
                992: {
                    columns: 2,
                },                
                1200: {
                    columns: 3,
                },                
            },
            margin: {
                x: 16,
                y: 16
            },
        });

        this.masonry.on(this.masonry.constants.EVENT_IMAGE_COMPLETE, function (ctx) {
            // const loader = document.querySelector('.js--masonry-loader');
            // if(loader) {
            //     loader.classList.add('loaded');
            // }
          });
        this.masonry.reInit();
    }

    createSlider(selector = '.js--slider') {
        if(!this.elementExists(selector)) {
            return false;
        }

        this.slider = tns({
          container: selector,
          items: 1,
          slideBy: 'page',
          gutter: 16,
          edgePadding: 0,
          nav: false,
          controlsContainer: '.slider-buttons',
          nav: false,
          autoplayButtonOutput: false,
          autoplay: false,
          responsive: {
              "0": {
                  edgePadding: 0,
                //   items: 1,
              },
              "768": {
                  edgePadding: 80
                // items: 1.2,
              },
              "992": {
                  edgePadding: 100
                // items: 1.35,
              },
              "1400": {
                  edgePadding: 200,
                //   items: 1.35,
              },
          }
        });
    }

  elementExists(selector) {
      return document.querySelector(selector) ? true : false;
  }
}